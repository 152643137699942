@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body,
    div#root {
        @apply h-screen min-h-screen font-open;
    }

    body {
        @apply font-open;
    }

    h1 {
        @apply text-6xl;
    }
    h2 {
        @apply text-5xl;
    }
    h3 {
        @apply text-4xl;
    }
    h4 {
        @apply text-3xl;
    }
    h5 {
        @apply text-2xl;
    }
    h6 {
        @apply text-xl;
    }
}

@layer components {
    .button {
        @apply bg-vdp-beige uppercase font-bold px-8 py-4 flex justify-center items-center;
    }

    .button.small {
        @apply px-4 py-2;
    }

    .button.vdp-red {
        @apply bg-vdp-red text-white;
    }

    .button.vdp-green {
        @apply bg-vdp-green text-white;
    }

    .button:disabled {
        @apply bg-vdp-beige text-vdp-red cursor-not-allowed;
    }

    .input {
        @apply w-full rounded bg-vdp-beige px-6 py-4 text-xl;
    }

    .input-error {
        @apply bg-vdp-red px-2 py-1 text-white text-sm font-bold italic;
    }

    .uploader {
        @apply w-full h-full border-4 border-dashed border-vdp-green rounded-lg flex items-center justify-center text-6xl text-vdp-green cursor-pointer;
    }

    .react-datepicker-wrapper,
    .react-datepicker__input-container,
    .react-datepicker__input-container input {
        display: block;
        width: 100%;
    }

    .divider span:not(:last-child)::after {
        content: ', ';
    }
}
